import { useCallback, useEffect, useRef, useState } from 'react';
import api from '../services/ApiService';
import { useUser } from '../contexts/UserContext';
import UserFiltrosDto from '../dto/userFiltrosDto';
import { useMap } from '../contexts/MapContext';
import debounce from 'lodash/debounce';
import useUserLocation from './useUserLocation';


const useLoadBars = (userLocation, zoomLevel, userPreferences) => {
  const [error, setError] = useState(null);
  const { filtros } = useUser();
  const { polygon, setBaresTodos, setEstaCargandoBares } = useMap();
  const { initialLocation } = useUserLocation();
  const abortControllerRef = useRef(null);

  const setLoading = useCallback((isLoading) => {
    setEstaCargandoBares(isLoading);
  }, [setEstaCargandoBares]);
    
  // Combina bares actuales y nuevos eliminando duplicados
  const mergeBars = useCallback((currentBars = [], newBars = []) => {
    if (!Array.isArray(newBars)) {
      return currentBars;
    }

    // Asegurarse de que solo se procesen bares válidos con _id
    const validNewBars = newBars.filter(bar => bar && bar._id);
    const barIds = new Set(currentBars.map(bar => bar._id));
    return [...currentBars, ...validNewBars.filter(bar => !barIds.has(bar._id))];
  }, []);

  const loadBars = useCallback(async () => {
  
    if (!userLocation) return;

      const userLocationIsDefault = 
        userLocation.lat === initialLocation.lat && 
        userLocation.lng === initialLocation.lng;
      
      if (userLocationIsDefault) return;

      // Cancela cualquier solicitud anterior
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      try {
        setLoading(true);

        console.log("loadBars -> SE LANZA");
        console.log(polygon);
        
        const userFiltrosDto = new UserFiltrosDto(userLocation, zoomLevel, userPreferences, filtros, polygon);
        console.log(userFiltrosDto);
        
        const baresCercanos = await api.bars.getBarsNearbyAdd(userFiltrosDto, { signal: controller.signal });

        // Si se canceló la petición, newBars es null y se evita actualizar el estado
        if (baresCercanos === null) {
          return;
        }
        console.log("setBaresTodos loadBars useLoadBars baresCercanos", baresCercanos);
        setBaresTodos(prevBars => mergeBars(prevBars, baresCercanos));
        
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err);
          console.error('Error loading bars:', err);
        }
      } finally {
        setLoading(false);
      }
    } ,[userLocation, zoomLevel, userPreferences, mergeBars, setBaresTodos, setLoading, polygon]);

  useEffect(() => {    
    if (userLocation && zoomLevel && polygon) {
      console.log("loadBars -> useEffect -> SE LANZA");
      console.log("loadBars -> useEffect -> userLocation", userLocation);
      console.log("loadBars -> useEffect -> zoomLevel", zoomLevel);
      console.log("loadBars -> useEffect -> polygon", polygon);
      loadBars();
    }
    return () => {
      // Limpia la última llamada si el componente se desmonta
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [userLocation, zoomLevel, loadBars, polygon]);

  return { error };
};

export default useLoadBars;