import axios from "axios";
import AuthUtils from "../utils/AuthUtils";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const users = "/users";
const bars = "/bars";
const barTags = "/barTags";
const drinkPrices = "/drinkPrices";
const eventComments = "/eventComments";
const events = "/events";
const promotions = "/promotions";
const userRatings = "/userRatings";
const images = "/images"

const apiBars = API_URL + bars;
const apiUsers = API_URL + users;

const api = {

    bars: {
        getBarsNearbyAdd: async (userFiltrosDto, { signal } = {}) => {
            try{
                const response = await axios.post(`${apiBars}/aplicarFiltros`,userFiltrosDto, { signal });
                console.log("loadBars -> getBarsNearbyAdd -> SE LANZA", response.data);
                return response.data;
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.error('Request canceled:', error.message);
                    // Retornamos null para indicar que fue cancelado y no propagar el error
                    return null;
                } else if (error.response) {
                    console.error('Server error:', error.response.data);
                } else {
                    console.error('Unknown error:', error.message);
                }
                throw error;
            }
        },
        getTypesGT10: async () => {
            try {
                const response = await axios.post(`${apiBars}/getTypesGT10/`);
                return response.data;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
                throw error;
            }
        },
        guardarInfoBar: async (userInfoBarDto) => {
            try {
                const response = await axios.post(`${apiBars}/guardarInfoBar`,userInfoBarDto);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        buscadorBares: async (searchTerm) => {
            try {
                const response = await axios.post(`${apiBars}/buscadorBares`,{searchTerm});
                return response.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        aplicarFiltros: async (userFiltrosDto) => {
            try {
                const response = await axios.post(`${apiBars}/aplicarFiltros`,userFiltrosDto);
                return response.data;
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.error('Request canceled:', error.message);
                } else if (error.response) {
                    console.error('Server error:', error.response.data);
                } else {
                    console.error('Unknown error:', error.message);
                }
                throw error;
            }
        },
        getBarById: async (barId) => {
            try {
                const response = await axios.get(`${apiBars}/getBarById/${barId}`);
                return response.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
    users: {
        login: async (userFormLogin) => {
            try {
                const response = await axios.post(`${apiUsers}/login`,userFormLogin);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        register: async (userFormLogin) => {
            try {
                const response = await axios.post(`${apiUsers}/register`,userFormLogin);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        authGoogle: async (profile) => {
            try {
                const response = await axios.post(`${apiUsers}/authGoogle`,profile);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        updateUser: async (editedUser) => {
            try {
                const response = await axios.post(`${apiUsers}/updateUser`,editedUser);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        addFavoriteBar: async (userId,barId) => {
            try {
                if (AuthUtils.isAuth()) {
                    const response = await axios.post(`${apiUsers}/addFavoriteBar`,userId, barId);
                    return response;
                }

                return null;

            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        removeFavoriteBar: async (userId,barId) => {
            try {
                const response = await axios.post(`${apiUsers}/removeFavoriteBar`,userId, barId);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        getFavoriteBars: async (userId) => {
            try {
                if (AuthUtils.isAuth()) {
                    const response = await axios.get(`${apiUsers}/getFavoriteBars/${userId}`);
                    return response.data;
                }
                return null;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        addWishBar: async (userId,barId) => {
            try {
                if (AuthUtils.isAuth()) {
                    const response = await axios.post(`${apiUsers}/addWishBar`,userId, barId);
                    return response;
                }

                return null;

            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        removeWishBar: async (userId,barId) => {
            try {
                const response = await axios.post(`${apiUsers}/removeWishBar`,userId, barId);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        getWishBars: async (userId) => {
            try {
                if (AuthUtils.isAuth()) {
                    const response = await axios.get(`${apiUsers}/getWishBars/${userId}`);
                    return response.data;
                }
                return null;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        guardarUserConfiguracion: async (userConfigDto) => {
            try {
                const response = await axios.post(`${apiUsers}/guardarUserConfiguracion`,userConfigDto);
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        pictureUpload: async (userPictureDto) => {
            try {
                const formData = new FormData();
                formData.append('user', userPictureDto.user);
                formData.append('imagen', userPictureDto.imagen);

                const response = await axios.post(`${apiUsers}/pictureUpload`,formData , {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }});

                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        
    }
}

export default api;