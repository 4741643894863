import React, { useEffect, useMemo, useState } from 'react';
import iconLocationBarTop from '../../assets/icons/bar.png';
import iconLocationCafeTop from '../../assets/icons/cafeteria.png';
import iconLocationWineTop from '../../assets/icons/cocktail.png';
import iconLocationBarRest from '../../assets/icons/circulo-verde.png';
import { MarkerF } from '@react-google-maps/api';


const setIcon = ({bar, isTop}) => {
  let icon = iconLocationBarRest;
  if (isTop) {
    for (const type of bar?.types) {
      switch (type) {
        case "bar":
          icon = iconLocationBarTop;
          break;
        case "cafe":
        case "bakery":
        case "restaurant":
        case "hotel":
        case "hostal":
          icon = iconLocationCafeTop;
          break;
        case "liquor_store":
          icon = iconLocationWineTop;
          break;
        default:
          icon = iconLocationBarTop;
          break;
      }
      //Salir del for con el primer tipo que encuentre.
      if (icon !== iconLocationBarRest) break;
    }
  }
  return icon;
}

const generateCustomIcon = (bar, isTop, callback) => {
  const baseIcon = setIcon({ bar, isTop });

  const img = new Image();
  img.src = baseIcon;
  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const iconSize = isTop ? 50 : 35;
    const textBoxWidth = 40;
    const textBoxHeight = 40;
    const borderRadius = 20;
    const padding = 10;
    const triangleSize = 7;

    const rating = bar.rating?.toFixed(1) || "N/A";
    const reviewCount = bar.numRatings || 0;
    const isTopBar = isTop && rating >= 4.5 && reviewCount >= 50; // TOP si cumple criterios

     // Determinar color de la valoración
     let ratingColor = "#ff4444"; // Rojo por defecto
     if (bar.rating >= 4.5) ratingColor = "#4CAF50"; // Verde
     else if (bar.rating >= 3.0) ratingColor = "#FFC107"; // Amarillo

    canvas.width = textBoxWidth + 10;
    canvas.height = textBoxHeight + triangleSize + iconSize;

    // Fondo del recuadro blanco
    //const colorRecuadro = "#fefcf0"
    //let colorRecuadro = "#3d403c"
    //let colorRecuadro = "#444444"
    
    //let colorRecuadro = "#363c3d"
    let colorRecuadro = "#227c73"
    
    ratingColor="#fff";

    ctx.fillStyle = colorRecuadro;
    ctx.strokeStyle = "#444"; // Borde gris oscuro
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(padding, textBoxHeight); // Punto inferior izquierdo
    ctx.lineTo(canvas.width - padding, textBoxHeight); // Inferior derecho
    ctx.quadraticCurveTo(canvas.width, textBoxHeight, canvas.width, textBoxHeight - borderRadius);
    ctx.lineTo(canvas.width, borderRadius); // Lateral derecho
    ctx.quadraticCurveTo(canvas.width, 0, canvas.width - borderRadius, 0);
    ctx.lineTo(borderRadius, 0); // Arriba
    ctx.quadraticCurveTo(0, 0, 0, borderRadius);
    ctx.lineTo(0, textBoxHeight - borderRadius); // Lateral izquierdo
    ctx.quadraticCurveTo(0, textBoxHeight, padding, textBoxHeight);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Pico apuntando al icono
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2 - triangleSize / 2, textBoxHeight);
    ctx.lineTo(canvas.width / 2, textBoxHeight + triangleSize);
    ctx.lineTo(canvas.width / 2 + triangleSize / 2, textBoxHeight);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

     // Texto de valoración
     ctx.fillStyle = ratingColor;
     ctx.font = "19px Arial";
     ctx.textAlign = "center";
     ctx.fillText(bar.rating.toFixed(1), canvas.width / 2, textBoxHeight / 1.5);

    // Dibuja el icono debajo del recuadro
    ctx.drawImage(img, (canvas.width - iconSize) / 2, textBoxHeight + triangleSize, iconSize, iconSize);

/*
    // Estrella decorativa ✨
    ctx.fillStyle = isTopBar ? "#D4AF37" : "#A0A0A0"; // Dorado si es TOP, gris si no
    ctx.fillStyle = parseFloat(rating) >= 4 ? "#227c73" : parseFloat(rating) >= 3 ? "#FFA500" : "#FF4C4C";   
    ctx.font = "bold 18px Arial";
    ctx.textAlign = "center";
    ctx.fillText(rating + " ★", textBoxWidth / 2, textBoxHeight / 1.8);
*/
    callback(canvas.toDataURL());
  };
};

const BarMarker = React.memo(({ bar, onClick, isTop }) => {
  const [iconUrl, setIconUrl] = useState(setIcon({ bar, isTop }));
  
  useEffect(() => {
    if (isTop) {
      generateCustomIcon(bar,isTop,setIconUrl);
    } else {
      setIconUrl(setIcon({ bar,isTop }));
    }

  }, [bar.rating, isTop]);

  return (
    <MarkerF
      key={bar._id}
      position={bar.location}
      icon={{
        url: iconUrl,
        scaledSize: isTop ? new window.google.maps.Size(31, 62) : new window.google.maps.Size(7, 7),
      }}
      onClick={() => onClick(bar)}
      animation={isTop ? window.google.maps.Animation.DROP : null}
    />
  );
}, (prevProps, nextProps) => prevProps.bar._id === nextProps.bar._id && prevProps.bar.rating === nextProps.bar.rating);





/*    <MarkerF
      key={bar._id}
      position={bar.location}
      icon={{
        url: setIcon({bar,isTop}),
        scaledSize: isTop ? new window.google.maps.Size(35, 35) : new window.google.maps.Size(7, 7),
      }}
      onClick={() => onClick(bar)}
      animation={isTop ? window.google.maps.Animation.DROP : null}
    />
  ), (prevProps, nextProps) => prevProps.bar._id === nextProps.bar._id);
*/

export default BarMarker;